import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/providers/auth';
import { IStopPoint } from '../components/TripRequest';

export const sendTripAsAdmin = (data: {
  startLat: number;
  startLong: number;
  finalLat?: number;
  finalLong?: number;
  userIds: string[];
  addressFrom: string;
  addressTo?: string;
  cityOfDestination?: string;
  clientTripId?: string;
  cityOfOrigin?: string;
  estimatedDistance: number;
  estimatedTime: number;
  scheduledDate?: Date | null;
  stopPoints?: IStopPoint[];
  vehicleCategoryId?: string;
  requestedVehicleClassId?: string;
  sectorId?: string;
  observation?: string;
}): Promise<any> => {
  return axios.post(`trips/sendAsAdmin`, data);
};

type UseUpdateCompaniesOptions = {
  config?: MutationConfig<typeof sendTripAsAdmin>;
};

export const useSendTripAsAdmin = ({
  config,
}: UseUpdateCompaniesOptions = {}) => {
  const { user } = useAuth();
  const { addNotification } = useNotificationStore();
  const navigate = useNavigate();
  return useMutation({
    ...config,
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: 'Sucesso',
      });
      queryClient.invalidateQueries('sector-trips');
      if (user.user.accessType === 'sector_admin') navigate('/trips');
    },
    mutationFn: sendTripAsAdmin,
  });
};
