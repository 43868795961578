/* eslint-disable no-param-reassign */
import * as z from 'zod';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Filter } from '@mui/icons-material';
import { cpfMask, phoneMask } from '@/utils/maskShared';
import { Form, InputField } from '@/components/Form';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { useCreateDriver, useDriversPlateList } from '@/features/drivers/api';
import { makeStyles } from '@mui/styles';
import { useAuth } from '@/providers/auth';
import { useRef, useState } from 'react';
import ptLocale from 'date-fns/locale/pt';

import { LocalizationProvider, TimePicker } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { DriversValues } from '../types';

export const schema = z.object({
  email: z.string({ required_error: 'Campo obrigatório' }),
  password: z
    .string({ required_error: 'Campo obrigatório' })
    .min(6, 'Mínimo 6 caracteres')
    .max(15, 'Máximo 15 caracteres'),
  name: z.string({ required_error: 'Campo obrigatório' }),
  phone: z
    .string({ required_error: 'Campo obrigatório' })
    .min(12, 'Minimo 12 caracteres')
    .max(15, 'Máximo 15 caracteres'),
  driverLicenseCategory: z
    .string({ required_error: 'Campo obrigatório' })
    .max(2, 'Máximo 2 caracteres'),
  individualRegistration: z
    .string({ required_error: 'Campo obrigatório' })
    .min(11, 'Máximo 11 caracteres')
    .max(14, 'Máximo 14 caracteres'),
  driverLicense: z
    .string()
    .min(10, 'Mínimo 10 caracteres')
    .max(11, 'Máximo 11 caracteres'),
  licensePlate: z.string().optional(),
  driverImage: z.unknown().nullable(),
  driverLicenseValidate: z
    .string({ required_error: 'Campo obrigatório' })
    .max(10, 'Máximo 10 caracteres'),
});

export function CreateDriver() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const hasTracker = !!user.user.company.trackerId;
  const vehiclePermission = !!user.user.company.permissions.find(
    data => data.name === 'veículos',
  );
  const { data } = useDriversPlateList();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [preview, setPreview] = useState('');
  const [showAddImageBox, setShowAddImageBox] = useState(true);
  const [workLoad, setWorkLoad] = useState<Date | null>(null);
  const [lunchInterval, setLunchInterval] = useState<Date | null>(null);
  const [startJourney, setStartJourney] = useState<Date | null>(null);

  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setSelectedFile(file);

    if (file) {
      const previewURL = URL.createObjectURL(file);
      setPreview(previewURL);

      setShowAddImageBox(false);
    }
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDivClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const { isLoading, mutateAsync } = useCreateDriver();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const useStyles = makeStyles(() => ({
    wrapper: {
      marginLeft: ({ isMobile }: { isMobile?: boolean }) =>
        isMobile ? '0px' : '10px',
    },
  }));

  const classes = useStyles({ isMobile });

  const handledriverLicenseValidate = (value: any) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2');
  };

  const handleSubmit = async (values: DriversValues) => {
    let workLoadInSeconds;
    if (workLoad) {
      const hours = workLoad.getHours();
      const minutes = workLoad.getMinutes();

      workLoadInSeconds = hours * 3600 + minutes * 60;
    }
    let lunchIntervalInSeconds;
    if (lunchInterval) {
      const hours = lunchInterval.getHours();
      const minutes = lunchInterval.getMinutes();

      lunchIntervalInSeconds = hours * 3600 + minutes * 60;
    }
    let startJourneyInSeconds;
    if (startJourney) {
      const hours = startJourney.getHours();
      const minutes = startJourney.getMinutes();
      startJourneyInSeconds = hours * 3600 + minutes * 60;
    }
    const statusSelected = true;
    const data = {
      ...values,
      status: statusSelected,
      urlImage: selectedFile,
      workLoad: workLoadInSeconds,
      lunchInterval: lunchIntervalInSeconds,
      startJourney: startJourneyInSeconds,
    };
    await mutateAsync(data);
  };

  return (
    <Paper sx={{ maxWidth: '1000px', margin: 'auto' }}>
      <Box>
        <Form<DriversValues, typeof schema>
          id="create-driver"
          onSubmit={async values => handleSubmit(values)}
          schema={schema}
        >
          {({ register, formState }) => (
            <>
              <Typography
                sx={{
                  color: theme.palette.mode === 'dark' ? 'white' : 'red',
                  fontSize: '22px',
                }}
              >
                Cadastro de motorista
              </Typography>
              <Grid container>
                <Grid item sm={6} xs={12}>
                  <InputField
                    size="small"
                    name="email"
                    type="text"
                    label="Login"
                    registration={register('email')}
                    error={!!formState.errors.email}
                    errorMessage={formState.errors.email?.message}
                    placeholder="Login do motorista para acesso no app"
                  />
                  <InputField
                    size="small"
                    name="password"
                    type="password"
                    label="Senha"
                    registration={register('password')}
                    error={!!formState.errors.password}
                    errorMessage={formState.errors.password?.message}
                  />
                  <InputField
                    size="small"
                    name="name"
                    type="text"
                    label="Nome"
                    registration={register('name')}
                    error={!!formState.errors.name}
                    errorMessage={formState.errors.name?.message}
                    placeholder="Nome do motorista"
                  />
                  <InputField
                    size="small"
                    name="individualRegistration"
                    type="text"
                    label="CPF"
                    registration={register('individualRegistration')}
                    error={!!formState.errors.individualRegistration}
                    errorMessage={
                      formState.errors.individualRegistration?.message
                    }
                    placeholder="999.999.999-99"
                    inputProps={{ minLength: 11, maxLength: 14 }}
                    onChange={(event: any) => {
                      const { value } = event.target;
                      event.target.value = cpfMask(value);
                    }}
                  />
                  <InputField
                    size="small"
                    name="phone"
                    type="text"
                    label="Telefone"
                    registration={register('phone')}
                    inputProps={{ minLength: 15, maxLength: 15 }}
                    error={!!formState.errors.phone}
                    errorMessage={formState.errors.phone?.message}
                    placeholder="(99) 99999-9999"
                    onChange={(event: any) => {
                      const { value } = event.target;
                      event.target.value = phoneMask(
                        value.replace(/[^\d]+/g, ''),
                      );
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box className={classes.wrapper}>
                    <InputField
                      size="small"
                      name="driverLicense"
                      type="text"
                      label="CNH"
                      registration={register('driverLicense')}
                      inputProps={{ minLength: 9, maxLength: 11 }}
                      error={!!formState.errors.driverLicense}
                      errorMessage={formState.errors.driverLicense?.message}
                      placeholder="999999999"
                    />
                    <InputField
                      size="small"
                      name="driverLicenseValidate"
                      type="text"
                      label="Validade CNH"
                      registration={register('driverLicenseValidate')}
                      error={!!formState.errors.driverLicenseValidate}
                      inputProps={{ minLength: 10, maxLength: 10 }}
                      errorMessage={
                        formState.errors.driverLicenseValidate?.message
                      }
                      placeholder="01/12/2030"
                      onChange={(event: any) => {
                        const { value } = event.target;
                        event.target.value = handledriverLicenseValidate(
                          value.replace(/[^\d]+/g, ''),
                        );
                      }}
                    />
                    <InputField
                      size="small"
                      name="driverLicenseCategory"
                      type="text"
                      label="Categoria CNH"
                      registration={register('driverLicenseCategory')}
                      error={!!formState.errors.driverLicenseCategory}
                      errorMessage={
                        formState.errors.driverLicenseCategory?.message
                      }
                    />
                    <LocalizationProvider
                      locale={ptLocale}
                      dateAdapter={AdapterDateFns}
                      dateFormats={{
                        hours24h: 'HH:mm',
                      }}
                    >
                      <TimePicker
                        label="Carga horária (opcional)"
                        value={workLoad}
                        onChange={newValue => {
                          if (newValue) setWorkLoad(newValue);
                        }}
                        renderInput={params => (
                          <TextField
                            sx={{ zIndex: 0, marginTop: '15px' }}
                            size="small"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider
                      locale={ptLocale}
                      dateAdapter={AdapterDateFns}
                      dateFormats={{
                        hours24h: 'HH:mm',
                      }}
                    >
                      <TimePicker
                        label="Intervalo de almoço (opcional)"
                        value={lunchInterval}
                        onChange={newValue => {
                          if (newValue) setLunchInterval(newValue);
                        }}
                        renderInput={params => (
                          <TextField
                            sx={{ zIndex: 0, marginTop: '15px' }}
                            size="small"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider
                      locale={ptLocale}
                      dateAdapter={AdapterDateFns}
                      dateFormats={{
                        hours24h: 'HH:mm',
                      }}
                    >
                      <TimePicker
                        label="Início da jornada (opcional)"
                        value={startJourney}
                        onChange={newValue => {
                          if (newValue) setStartJourney(newValue);
                        }}
                        renderInput={params => (
                          <TextField
                            sx={{ zIndex: 0, marginTop: '15px' }}
                            size="small"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <Box
                      style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        marginTop: '5px',
                        position: 'relative',
                        height: '118px',
                      }}
                    >
                      <Box
                        style={{
                          flex: 1,
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: showAddImageBox ? 'flex' : 'none',
                          marginTop: '5px',
                          height: '118px',
                          borderRadius: '10px',
                          border: '1px dotted gray',
                          flexDirection: 'column',
                          cursor: 'pointer',
                        }}
                        onClick={handleDivClick}
                      >
                        <form
                          action="/drivers/create"
                          method="POST"
                          encType="multipart/form-data"
                        >
                          <input
                            name="driverImage"
                            type="file"
                            accept=".jpeg, .jpg, .png"
                            onChange={handleFileInput}
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                          />
                        </form>
                        <Filter style={{ color: 'gray' }} />
                        <Typography>Adicionar imagem</Typography>
                      </Box>
                      {preview && (
                        <Box
                          sx={{
                            background: 'white',
                            width: '118px',
                            height: '118px',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            overflow: 'hidden',
                            cursor: 'pointer',
                            position: 'relative',
                            boxShadow: '0px 0px 10px 0px #0000004c',
                          }}
                          onClick={handleDivClick}
                        >
                          <Filter
                            style={{ position: 'absolute', color: '#fff' }}
                          />
                          <img
                            src={preview}
                            alt="Preview"
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                    {!vehiclePermission && (
                      <>
                        {hasTracker ? (
                          <InputField
                            size="small"
                            name="licensePlate"
                            select={hasTracker}
                            label="Placa"
                            registration={register('licensePlate')}
                            error={!!formState.errors.licensePlate}
                            errorMessage={
                              formState.errors.licensePlate?.message
                            }
                          >
                            <MenuItem value="">Livre</MenuItem>
                            {data?.length > 0 &&
                              data !== 'Não houve resultados!' &&
                              data.map((item: any, key: any) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <MenuItem key={key} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                          </InputField>
                        ) : (
                          <InputField
                            size="small"
                            name="licensePlate"
                            select={hasTracker}
                            label="Placa"
                            registration={register('licensePlate')}
                            error={!!formState.errors.licensePlate}
                            errorMessage={
                              formState.errors.licensePlate?.message
                            }
                          />
                        )}
                      </>
                    )}
                  </Box>
                </Grid>

                <Grid />
                <Box sx={{ display: 'flex', marginTop: '10px', gap: '5px' }}>
                  <Button
                    variant="contained"
                    color="success"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? 'Carregando' : 'Adicionar'}
                  </Button>
                  <Button
                    onClick={() => navigate(-1)}
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                  >
                    Voltar
                  </Button>
                </Box>
              </Grid>
            </>
          )}
        </Form>
      </Box>
    </Paper>
  );
}
