/* eslint-disable import/no-duplicates */
import { Loading, Modal } from '@/components';
import ptLocale from 'date-fns/locale/pt';
import { UserValues, useUsers } from '@/features/users';
import { DateTimePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

import * as z from 'zod';
import { useState } from 'react';
import { useNotificationStore } from '@/stores';
import { AddCircle, Settings } from '@mui/icons-material';
import { useSectorsList } from '@/features/sectors';
import { useAuth } from '@/providers/auth';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useHasPermission } from '@/hooks/useHasPermission';
import { useListClassesAsCompanyAdmin } from '@/features/vehicleClasses';
import { useSaveRecurringTrips, useSendTripAsAdmin } from '../../api';
import { StopPoints } from './StopPoints';
import { SelectCostCenter } from './SelectCostCenter';
import { SelectPassenger } from './SelectPassenger';
import { RequestTripConfirmation } from '../RequestTripConfirmation';
import { useCheckPrice } from '../../api/checkPrice';
import { SelectTypeOfVehicle } from './SelectTypeOfVehicle';
import { SelectFavoriteAddress } from '../SelectFavoriteAdresses';
import { IAddressDTO } from '../../types';
import { AddressInput } from '../AddressInput/AddressInput';
import { RecurringTripDays } from '../RecurringTripDays';
import { RecurringTripsList } from '../RecurringTripsList';
import { CreateSectorModal } from './CreateSectorModal';
import { CreatePassengerModal } from './CreatePassengerModal';
import { SelectClassOfVehicle } from './SelectClassOfVehicle';

export interface IStopPoint {
  order: number;
  lat: number;
  long: number;
  address: string;
}

const schema = z.object({
  addressFrom: z.string(),
  addressTo: z.string().optional(),
  cityOfOrigin: z.string().optional(),
  cityOfDestination: z.string().optional(),
  startLat: z.number(),
  startLong: z.number(),
  finalLat: z.number().optional(),
  finalLong: z.number().optional(),
  scheduledDate: z.string().optional(),
  sectorId: z.string().optional(),
  vehicleCategoryId: z.string().optional(),
  vehicleClassId: z.string().optional(),
  observation: z.string().optional(),
  clientTripId: z.string().optional(),
});

export const TripRequest = () => {
  const [observation, setObservation] = useState('');
  const [clientTripId, setClientTripId] = useState('');
  const { close, isOpen, open } = useDisclosure();
  const check = useHasPermission('preço estimado');
  const { user } = useAuth();
  const { addNotification } = useNotificationStore();
  const [isScheduled, setIsScheduled] = useState(false);
  const [scheduledDate, setScheduledDate] = useState<Date | null>();
  const [recurringDate, setRecurringDate] = useState<Date | null>(new Date());
  const [stopPoints, setStopPoints] = useState<IStopPoint[]>([]);
  const [disposition, setDisposition] = useState(false);
  const [needSpecificCar, setNeedSpecificCar] = useState(false);
  const [userIds, setUserIds] = useState<{ key: number; id: string }[]>([
    {
      key: Date.now(),
      id: '',
    },
  ]);
  const [formState, setFormState] = useState<'request' | 'recurring'>(
    'request',
  );
  const [selectedRecurringTripDays, setSelectedRecurringTripDays] = useState<
    {
      id: number;
      name: string;
    }[]
  >([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { isLoading, data } = useUsers();
  const { data: sectorsList, isLoading: loadingSectorList } = useSectorsList();
  const { isOpen: isRecurringTripsGridOpen, toggle: toggleRecurringTripsGrid } =
    useDisclosure();
  const { mutateAsync: requestTrip, isLoading: isSendingTrip } =
    useSendTripAsAdmin();
  const { mutateAsync: saveRecurringTrip, isLoading: isSavingRecurringTrip } =
    useSaveRecurringTrips();
  const {
    mutateAsync: checkPrice,
    isLoading: isChecking,
    data: estimatedTripData,
  } = useCheckPrice();
  const {
    isOpen: isCreateSectorOpen,
    open: openCreateSector,
    close: closeCreateSector,
  } = useDisclosure();
  const {
    isOpen: isCreatePassengerOpen,
    open: openCreatePassenger,
    close: closeCreatePassenger,
  } = useDisclosure();

  const { data: classes } = useListClassesAsCompanyAdmin();

  const { handleSubmit, setValue, getValues, watch } = useForm({
    resolver: schema && zodResolver(schema),
  });

  const addressFrom = watch('addressFrom');
  const addressTo = watch('addressTo');
  const sectorId = watch('sectorId');

  const [selectFavoriteAddress, setSelectFavoriteAddress] = useState({
    open: false,
    handler: (data: IAddressDTO) => {},
  });

  const handleSelectFavoriteAddressToOrigin = (setValue: any) => {
    setSelectFavoriteAddress({
      open: true,
      handler: (address: IAddressDTO) => {
        setValue('addressFrom', address.address);
        setValue('cityOfOrigin', address.city);
        setValue('startLat', address.latitude);
        setValue('startLong', address.longitude);
        setSelectFavoriteAddress({
          open: false,
          handler: (data: IAddressDTO) => {},
        });
      },
    });
  };

  const handleSelectFavoriteAddressToStopPoint = ({
    setStopPoints,
    order,
  }: {
    setStopPoints: React.Dispatch<React.SetStateAction<IStopPoint[]>>;
    order: number;
  }) => {
    setSelectFavoriteAddress({
      open: true,
      handler: (address: IAddressDTO) => {
        const findStopPoint = stopPoints.find(
          stopPoint => stopPoint.order === order,
        );
        if (!findStopPoint) return;
        findStopPoint.address = address.address;
        findStopPoint.lat = address.latitude;
        findStopPoint.long = address.longitude;
        setStopPoints((prev: IStopPoint[]) => [...prev]);
        setSelectFavoriteAddress({
          open: false,
          handler: (data: IAddressDTO) => {},
        });
      },
    });
  };

  const handleSelectFavoriteAddressToDestination = (setValue: any) => {
    setSelectFavoriteAddress({
      open: true,
      handler: (address: IAddressDTO) => {
        setValue('addressTo', address.address);
        setValue('cityOfDestination', address.city);
        setValue('finalLat', address.latitude);
        setValue('finalLong', address.longitude);
        setSelectFavoriteAddress({
          open: false,
          handler: (data: IAddressDTO) => {},
        });
      },
    });
  };

  const handleAddressFrom = (val: any, setValue: any) => {
    geocodeByAddress(val?.label)
      .then(results => {
        const city = results[0].address_components.find(item =>
          item.types.includes('administrative_area_level_2'),
        );
        setValue('cityOfOrigin', city?.long_name);
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        setValue('addressFrom', val.label);
        setValue('startLat', lat);
        setValue('startLong', lng);
      });
  };

  const cleanAddressFrom = (setValue: any) => {
    setValue('addressFrom', null);
    setValue('startLat', null);
    setValue('startLong', null);
  };

  const handleAddressTo = (val: any, setValue: any) => {
    geocodeByAddress(val?.label)
      .then(results => {
        const city = results[0].address_components.find(item =>
          item.types.includes('administrative_area_level_2'),
        );
        setValue('cityOfDestination', city?.long_name);
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        setValue('addressTo', val.label);
        setValue('finalLat', lat);
        setValue('finalLong', lng);
      });
  };

  const cleanAddressTo = (setValue: any) => {
    setValue('addressTo', '');
    setValue('finalLat', 0);
    setValue('finalLong', 0);
  };

  const handleAddStopPoint = (val: any, setValue: any, id: number) => {
    const findStopPoint = stopPoints.find(stopPoint => stopPoint.order === id);
    if (!findStopPoint) return;
    geocodeByAddress(val?.label)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        findStopPoint.address = val.label;
        findStopPoint.lat = lat;
        findStopPoint.long = lng;
        setValue((prev: any) => [...prev]);
      });
  };

  const cleanStopPoint = (setValue: any, id = 0) => {
    const filteredStopPoints = stopPoints.filter(
      stopPoint => stopPoint.order !== id,
    );

    setValue(filteredStopPoints);
  };

  if (isLoading || loadingSectorList) {
    return <Loading isLoading={isLoading} />;
  }

  const onSubmit = (values: {
    addressFrom: string;
    addressTo: string;
    startLat: number;
    startLong: number;
    finalLat: number;
    finalLong: number;
    sectorId?: string;
    vehicleCategoryId?: string;
    vehicleClassId?: string;
    cityOfDestination?: string;
    cityOfOrigin?: string;
    clientTripId?: string;
    observation?: string;
  }) => {
    if (!userIds[0].id) {
      addNotification({
        title: 'Erro',
        type: 'error',
        message: 'Por favor, selecione um passageiro!',
      });
      return;
    }
    if (user.user.accessType === 'company_admin' && !values.sectorId) {
      addNotification({
        title: 'Erro',
        type: 'error',
        message: 'Por favor, selecione um setor!',
      });
      return;
    }

    const findIncompleteStopPoint = stopPoints.find(
      stopPoint => !stopPoint.lat,
    );
    if (findIncompleteStopPoint) {
      addNotification({
        title: 'Erro',
        type: 'error',
        message: 'Por favor, preencha os endereços!',
      });
      return;
    }

    const tripValues = {
      addressFrom: values.addressFrom,
      addressTo: !disposition ? values.addressTo : undefined,
      startLat: values.startLat,
      startLong: values.startLong,
      finalLat: values.finalLat,
      finalLong: values.finalLong,
      sectorId: values.sectorId,
      cityOfOrigin: values.cityOfOrigin,
      cityOfDestination: values.cityOfDestination,
      scheduledDate,
      estimatedDistance: estimatedTripData?.estimatedDistance || 0,
      estimatedTime: estimatedTripData?.estimatedTime || 0,
      vehicleCategoryId: values.vehicleCategoryId,
      vehicleClassId: values.vehicleClassId,
      stopPoints,
      clientTripId,
      observation,
      userIds: userIds.filter(data => data.id !== '').map(user => user.id),
    };

    if (formState === 'request') {
      if (!check || disposition) {
        // If the disposition option is selected, it means that the trip does not have a specific destination
        // If the company does not have the permission to check the estimated price, the trip will be sent directly
        requestTrip(
          {
            ...tripValues,
          },
          {
            onSuccess: () => {
              setUserIds([
                {
                  key: Date.now(),
                  id: '',
                },
              ]);
              setIsScheduled(false);
              setScheduledDate(null);
              close();
            },
          },
        );
      } else {
        checkPrice(
          {
            ...tripValues,
            addressTo: values.addressTo,
            vehicleClassId: getValues('vehicleClassId'),
          },
          {
            onSuccess: () => {
              open();
            },
          },
        );
      }
    } else {
      if (!recurringDate || !new Date(recurringDate).getTime()) {
        addNotification({
          title: 'Erro',
          type: 'error',
          message: 'Por favor, selecione uma data válida!',
        });
        return;
      }
      saveRecurringTrip({
        ...tripValues,
        addressTo: values.addressTo,
        cityOfDestination: values.cityOfDestination || '',
        cityOfOrigin: values.cityOfOrigin || '',
        minute: recurringDate?.getMinutes() || 0,
        hour: recurringDate?.getHours() || 0,
        days: selectedRecurringTripDays.map(data => ({
          day: data.id,
        })),
      });
    }
  };

  return (
    <>
      <Modal
        onClose={() =>
          setSelectFavoriteAddress({ open: false, handler: () => {} })
        }
        style={{
          backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '',
        }}
        open={selectFavoriteAddress.open}
        size="xs"
        dialogContent={
          <SelectFavoriteAddress
            onSelectAddress={selectFavoriteAddress.handler}
          />
        }
      />
      <Modal
        onClose={() => closeCreateSector()}
        style={{
          backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '',
        }}
        open={isCreateSectorOpen}
        size="xs"
        title=""
        dialogContent={
          <CreateSectorModal
            onSuccess={id => {
              setValue('sectorId', id);
              closeCreateSector();
            }}
          />
        }
      />
      <Modal
        onClose={() => closeCreatePassenger()}
        style={{
          backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '',
        }}
        open={isCreatePassengerOpen}
        size="sm"
        title=""
        dialogContent={
          <CreatePassengerModal onSuccess={() => closeCreatePassenger()} />
        }
      />
      <Modal
        onClose={close}
        open={isOpen}
        size="xs"
        dialogContent={
          <RequestTripConfirmation
            onClose={() => close()}
            isLoading={isSendingTrip}
            onConfirm={async () => {
              const addressFrom = getValues('addressFrom');
              const addressTo = getValues('addressTo');
              const startLat = getValues('startLat');
              const startLong = getValues('startLong');
              const finalLat = getValues('finalLat');
              const finalLong = getValues('finalLong');
              const sectorId = getValues('sectorId');
              const vehicleCategoryId = getValues('vehicleCategoryId');
              const vehicleClassId = getValues('vehicleClassId');
              const cityOfOrigin = getValues('cityOfOrigin');
              const cityOfDestination = getValues('cityOfDestination');

              requestTrip(
                {
                  addressFrom,
                  addressTo,
                  finalLat,
                  finalLong,
                  startLat,
                  startLong,
                  sectorId,
                  vehicleCategoryId,
                  requestedVehicleClassId: vehicleClassId,
                  scheduledDate,
                  cityOfOrigin,
                  cityOfDestination,
                  clientTripId,
                  observation,
                  estimatedDistance: estimatedTripData?.estimatedDistance || 0,
                  estimatedTime: estimatedTripData?.estimatedTime || 0,
                  stopPoints,
                  userIds: userIds
                    .filter(data => data.id !== '')
                    .map(user => user.id),
                },
                {
                  onSuccess: () => {
                    setUserIds([
                      {
                        key: Date.now(),
                        id: '',
                      },
                    ]);
                    setIsScheduled(false);
                    setScheduledDate(null);
                    close();
                  },
                },
              );
            }}
            price={estimatedTripData?.estimatedPrice || 0}
          />
        }
        title="Confirmação"
      />
      <Modal
        onClose={toggleRecurringTripsGrid}
        title=""
        open={isRecurringTripsGridOpen}
        size="xl"
        dialogContent={<RecurringTripsList />}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <>
            <Box
              sx={{
                borderRadius: '30px',
                display: 'flex',
                flexDirection: 'column',
                width: isMobile ? '100%' : '600px',
              }}
            >
              <Paper>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '10px 20px',
                  }}
                >
                  <Button
                    color={formState === 'request' ? 'primary' : 'inherit'}
                    onClick={() => setFormState('request')}
                    variant={formState === 'request' ? 'outlined' : 'text'}
                  >
                    Solicitar viagem
                  </Button>
                  <Box>
                    <Button
                      color={formState === 'recurring' ? 'primary' : 'inherit'}
                      onClick={() => setFormState('recurring')}
                      variant={formState === 'recurring' ? 'outlined' : 'text'}
                    >
                      Viagem recorrente
                    </Button>
                    {formState === 'recurring' && (
                      <Tooltip arrow title="Configurações" placement="right">
                        <IconButton>
                          <Settings
                            fontSize="small"
                            sx={{
                              '&:hover': {
                                transform: 'rotate(90deg)',
                              },
                              transform: 'rotate(0deg)',
                              transition: 'ease-in-out 0.5s',
                            }}
                            onClick={() => toggleRecurringTripsGrid()}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                </Box>
              </Paper>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}
                >
                  <AddressInput
                    clean={cleanAddressFrom}
                    handleAddress={handleAddressFrom}
                    setValue={setValue}
                    placeholder={addressFrom || 'Origem'}
                    onAddFavoriteAddress={() =>
                      handleSelectFavoriteAddressToOrigin(setValue)
                    }
                    value={addressFrom}
                  />
                  <Box
                    sx={{
                      width: '240px',
                      marginTop: '15px',
                      marginLeft: '40px',
                    }}
                  >
                    <TextField
                      size="small"
                      placeholder="Observação (complemento)"
                      value={observation}
                      onChange={e => setObservation(e.target.value)}
                    />
                  </Box>
                  {!disposition && (
                    <>
                      {stopPoints.length >= 1 &&
                        stopPoints
                          .sort((a, b) => a.order - b.order)
                          .map(stopPoint => (
                            <AddressInput
                              key={stopPoint.order}
                              clean={cleanStopPoint}
                              handleAddress={handleAddStopPoint}
                              setValue={setStopPoints}
                              placeholder="Parada"
                              id={stopPoint.order}
                              isStopPoint
                              onAddFavoriteAddress={() =>
                                handleSelectFavoriteAddressToStopPoint({
                                  setStopPoints,
                                  order: stopPoint.order,
                                })
                              }
                              value={stopPoint.address}
                            />
                          ))}
                      <AddressInput
                        clean={cleanAddressTo}
                        handleAddress={handleAddressTo}
                        setValue={setValue}
                        placeholder={addressTo || 'Destino'}
                        onAddFavoriteAddress={() =>
                          handleSelectFavoriteAddressToDestination(setValue)
                        }
                        value={addressTo}
                      />
                    </>
                  )}
                  {formState === 'request' && (
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        marginTop: '15px',
                      }}
                    >
                      <FormControlLabel
                        sx={{
                          width: '100%',
                          color: 'gray',
                        }}
                        control={
                          <Checkbox
                            onChange={() => {
                              setDisposition(prev => !prev);
                              setStopPoints([]);
                            }}
                            checked={disposition}
                          />
                        }
                        label="Motorista à disposição"
                      />
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                    }}
                  >
                    {formState === 'request' && (
                      <FormControlLabel
                        sx={{
                          width: '100%',
                          color: 'gray',
                        }}
                        control={
                          <Checkbox
                            onChange={() => {
                              setIsScheduled(prev => !prev);
                              setScheduledDate(null);
                            }}
                            checked={isScheduled}
                          />
                        }
                        label="Agendar"
                      />
                    )}
                    {!disposition && (
                      <StopPoints
                        stopPoints={stopPoints}
                        setStopPoints={setStopPoints}
                      />
                    )}
                  </Box>
                  {isScheduled && (
                    <LocalizationProvider
                      locale={ptLocale}
                      dateAdapter={AdapterDateFns}
                    >
                      <DateTimePicker
                        label="Data inicial"
                        value={scheduledDate}
                        onChange={newValue => {
                          setScheduledDate(newValue);
                        }}
                        renderInput={params => (
                          <TextField
                            sx={{ zIndex: 0 }}
                            size="small"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      marginTop: '15px',
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        width: '100%',
                        color: 'gray',
                      }}
                      control={
                        <Checkbox
                          onChange={() =>
                            setNeedSpecificCar(prev => {
                              if (prev) {
                                setValue('vehicleCategoryId', undefined);
                                return false;
                              }
                              return true;
                            })
                          }
                          checked={needSpecificCar}
                        />
                      }
                      label="Preciso de um tipo específico de veículo"
                    />
                  </Box>
                  {needSpecificCar && (
                    <Box
                      sx={{
                        width: '100%',
                        marginTop: '2px',
                      }}
                    >
                      <SelectTypeOfVehicle
                        onChange={e => {
                          setValue('vehicleCategoryId', e.target.value);
                        }}
                      />
                    </Box>
                  )}
                  <Box
                    sx={{
                      width: '100%',
                      marginTop: '2px',
                    }}
                  >
                    <SelectClassOfVehicle
                      classes={classes || []}
                      onChange={e => {
                        setValue('vehicleClassId', e.target.value);
                      }}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}
                >
                  {userIds.map(passenger => (
                    <SelectPassenger
                      onAdd={() => openCreatePassenger()}
                      key={passenger.key}
                      data={
                        data?.filter(
                          (user: UserValues) => user.accessType === 'user',
                        ) || []
                      }
                      onChange={option => {
                        const alreadySelected = userIds.find(
                          user => user.id === option?.id,
                        );
                        if (alreadySelected) {
                          addNotification({
                            title: 'Esse passageiro já foi selecionado',
                            type: 'warning',
                          });
                          return;
                        }
                        const passengerIndex = userIds.findIndex(
                          data => data.key === passenger.key,
                        );

                        setUserIds([
                          ...userIds.slice(0, passengerIndex),
                          {
                            key: passenger.key,
                            id: option?.id || '',
                          },
                          ...userIds.slice(passengerIndex + 1, userIds.length),
                        ]);
                      }}
                      onClose={() => {
                        if (userIds.length === 1) {
                          addNotification({
                            title: 'É necessário ao menos um passageiro',
                            type: 'warning',
                          });
                        }
                        const filteredPassengers = userIds.filter(
                          data => data.key !== passenger.key,
                        );
                        setUserIds(filteredPassengers);
                      }}
                      userIds={userIds}
                    />
                  ))}
                  <Tooltip
                    sx={{ alignSelf: 'flex-end' }}
                    title="Adicionar passageiro"
                  >
                    <IconButton
                      onClick={() => {
                        setUserIds(prev => [
                          ...prev,
                          {
                            id: '',
                            key: Date.now(),
                          },
                        ]);
                      }}
                    >
                      <AddCircle sx={{ color: 'green' }} />
                    </IconButton>
                  </Tooltip>
                  {formState === 'recurring' && (
                    <>
                      <RecurringTripDays
                        setSelectedRecurringTripDays={
                          setSelectedRecurringTripDays
                        }
                        selectedRecurringTripDays={selectedRecurringTripDays}
                        date={recurringDate || new Date()}
                        setScheduledDate={setRecurringDate}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
              {user.user.accessType === 'company_admin' && (
                <Box
                  sx={{
                    padding: '10px 20px',
                    width: isMobile ? '100%' : '300px',
                    marginTop: '-15px',
                  }}
                >
                  <SelectCostCenter
                    onChange={e => {
                      setValue('sectorId', e.target.value);
                    }}
                    value={sectorId}
                    onClickAdd={() => openCreateSector()}
                    data={sectorsList || []}
                  />
                </Box>
              )}
              <Box
                sx={{
                  width: '300px',
                  marginTop: '15px',
                  marginLeft: '20px',
                }}
              >
                <TextField
                  size="small"
                  placeholder="Id (opcional)"
                  value={clientTripId}
                  onChange={e => setClientTripId(e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '20px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderBottomRightRadius: '30px',
                  borderBottomLeftRadius: '30px',
                }}
              >
                <LoadingButton
                  disabled={
                    isSendingTrip ||
                    isChecking ||
                    isSavingRecurringTrip ||
                    (formState === 'recurring' &&
                      !selectedRecurringTripDays.length)
                  }
                  loading={isSendingTrip || isChecking || isSavingRecurringTrip}
                  type="submit"
                  variant="contained"
                  color="success"
                  sx={{ marginTop: '10px', borderRadius: '15px' }}
                >
                  {formState === 'request' ? 'Solicitar viagem' : 'Salvar'}
                </LoadingButton>
              </Box>
            </Box>
          </>
        </form>
      </Box>
    </>
  );
};
