/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  Snackbar,
  Checkbox,
} from '@mui/material';
import { Modal, Table } from '@/components';

import {
  ContentCopy,
  DeleteSharp,
  Replay,
  ChangeCircle,
  Layers,
  LayersOutlined,
  Place,
  PlayCircleOutline,
  SportsScore,
  CheckCircleOutline,
  CallMissedOutgoing,
} from '@mui/icons-material';

import { useDisclosure } from '@/hooks/useDisclosure';

import { DriversValues } from '@/features/drivers';
import { BiPencil } from 'react-icons/bi';
import { loadOpenTripsResponse } from '../../types';
import { RejectTrip } from '../RejectTrip';
import {
  useFinishExternalCompany,
  useMarkExternalTripAsAccepted,
  useRejectTrip,
  useStartExternalCompany,
  useUndoJoinedTrip,
} from '../../api';
import { RedirectDriver } from '../RedirectDriver/redirectDriver';
import { useUndoExternalCompany } from '../../api/undoExternalCompany';
import { IStopPoint } from '../TripRequest';
import { UpdateTripAsRenter } from '../UpdateTripAsRenter';
import { EditFinishedTrip } from '../EditFinishedTrip';

export const OpenTripGrid = ({
  openTripsData,
  AcceptTripModal,
  drivers,
  selectedRows,
  setSelectedRows,
}: {
  AcceptTripModal: ({ userId, id, vehicleCategoryName }: any) => void;
  openTripsData: loadOpenTripsResponse[];
  drivers: DriversValues[];
  selectedRows: Set<string>;
  setSelectedRows: any;
}) => {
  const { isOpen, toggle, close } = useDisclosure();
  const [selectedId, setSelectedId] = useState('');
  const [refusedTripReason, setRefusedTripReason] = useState('');
  const [showLimitExceededToast, setShowLimitExceededToast] = useState(false);

  const [showCopyToast, setShowCopyToast] = useState(false);
  const {
    mutateAsync: undoExternalCompany,
    isLoading: isCancelingExternalCompany,
  } = useUndoExternalCompany();
  const {
    mutateAsync: startExternalCompany,
    isLoading: isStartingExternalCompany,
  } = useStartExternalCompany();
  const {
    mutateAsync: markExternalTripAsAccepted,
    isLoading: isAcceptingExternalTrip,
  } = useMarkExternalTripAsAccepted();
  const [selectFinishedTripToEdit, setSelectFinishedTripToEdit] = useState<{
    manualInitialOdometer?: number;
    manualFinalOdometer?: number;
    manualStartOdometer?: number;
    tripId: string;
  } | null>(null);
  const [selectedTripToEdit, setSelectedTripToEdit] = useState<{
    scheduledDate: string;
    id: string;
    addressFrom: string;
    addressTo: string;
    finalLat: string;
    finalLong: string;
    startLat: string;
    startLong: string;
    stopPoints: IStopPoint[];
    users: string[];
    cityOfDestination: string;
    cityOfOrigin: string;
    observation?: string;
    sectorId: string;
    vehicleCategoryId?: string;
    companyId: string;
  } | null>(null);

  const { mutateAsync: finishExternalCompany, isLoading: isFinishing } =
    useFinishExternalCompany();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { mutateAsync, isLoading: isRejecting } = useRejectTrip();
  const { mutateAsync: undoJoinedTrip, isLoading: isLoadingUndoJoinedTrip } =
    useUndoJoinedTrip();

  const handleDelete = () => {
    mutateAsync(
      {
        tripId: selectedId,
        refusedTripReason,
      },
      { onSuccess: () => close() },
    );
  };

  const handleCopyToClipboard = (content: string) => {
    navigator.clipboard.writeText(content).then(() => {
      setShowCopyToast(true);
      setTimeout(() => {
        setShowCopyToast(false);
      }, 2000);
    });
  };

  const generateLinkToViewTrip = (tripId: string) => {
    const url = `${window.location.origin}/tripVisualization/${tripId}`;
    navigator.clipboard.writeText(url).then(() => {
      setShowCopyToast(true);
      setTimeout(() => {
        setShowCopyToast(false);
      }, 2000);
    });
  };

  const handleCheckboxChange = (id: string) => {
    setSelectedRows((prev: Iterable<unknown> | null | undefined) => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else if (newSet.size < 4) {
        newSet.add(id);
      } else {
        setShowLimitExceededToast(true);
        setTimeout(() => {
          setShowLimitExceededToast(false);
        }, 2000);
      }
      return newSet;
    });
  };

  return (
    <>
      {selectedTripToEdit && (
        <Modal
          open={!!selectedTripToEdit}
          onClose={() => setSelectedTripToEdit(null)}
          size="md"
          title="Modificar agendamento"
          dialogContent={
            <UpdateTripAsRenter
              scheduledDate={selectedTripToEdit.scheduledDate}
              onSuccess={() => setSelectedTripToEdit(null)}
              companyId={selectedTripToEdit.companyId}
              id={selectedTripToEdit.id}
              addressFrom={selectedTripToEdit.addressFrom}
              addressTo={selectedTripToEdit.addressTo}
              finalLat={Number(selectedTripToEdit.finalLat)}
              finalLong={Number(selectedTripToEdit.finalLong)}
              startLat={Number(selectedTripToEdit.startLat)}
              startLong={Number(selectedTripToEdit.startLong)}
              stopPoints={selectedTripToEdit.stopPoints}
              users={selectedTripToEdit.users}
              cityOfDestination={selectedTripToEdit.cityOfDestination}
              cityOfOrigin={selectedTripToEdit.cityOfOrigin}
              observation={selectedTripToEdit.observation}
              sectorId={selectedTripToEdit.sectorId}
              vehicleCategoryId={selectedTripToEdit.vehicleCategoryId}
            />
          }
        />
      )}
      {selectFinishedTripToEdit?.tripId && (
        <Modal
          open={!!selectFinishedTripToEdit}
          onClose={() => setSelectFinishedTripToEdit(null)}
          size="xs"
          title="Modificar viagem finalizada"
          dialogContent={
            <EditFinishedTrip
              manualFinalOdometer={selectFinishedTripToEdit.manualFinalOdometer}
              manualInitialOdometer={
                selectFinishedTripToEdit.manualInitialOdometer
              }
              manualStartOdometer={selectFinishedTripToEdit.manualStartOdometer}
              tripId={selectFinishedTripToEdit.tripId}
              onSuccess={() => setSelectFinishedTripToEdit(null)}
            />
          }
        />
      )}
      <Table<loadOpenTripsResponse>
        data={openTripsData || []}
        rowsPerPage={isMobile ? 5 : 25}
        selectedRows={selectedRows}
        width="120px"
        fontSize={11}
        headerFontSize={11}
        columns={[
          {
            title: 'Motorista',
            field: 'driver',
            colSpan: 1,
            Cell({
              entry: {
                userId,
                id,
                vehicleCategoryName,
                driverId,
                status,
                driver,
                externalCompany,
                isJoinedTrip,
              },
            }) {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {status === 'requested' && !isJoinedTrip && (
                    <Checkbox
                      icon={<LayersOutlined />}
                      checkedIcon={<Layers sx={{ color: '#fff' }} />}
                      onChange={() => handleCheckboxChange(id)}
                      checked={selectedRows.has(id)}
                    />
                  )}
                  {driverId ? (
                    <Box
                      sx={{
                        marginLeft: '10px',
                        width: isMobile ? '90px' : '150px',
                      }}
                    >
                      {status === 'pending' || status === 'scheduled' ? (
                        <RedirectDriver
                          drivers={drivers || []}
                          driverId={driverId}
                          id={id}
                        />
                      ) : (
                        <Typography sx={{ fontSize: '12px' }}>
                          {driver}
                        </Typography>
                      )}
                    </Box>
                  ) : (
                    <>
                      {status === 'requested' && (
                        <Box
                          onClick={() =>
                            AcceptTripModal({ userId, id, vehicleCategoryName })
                          }
                          sx={{
                            background: '#3682d8',
                            borderRadius: '10px',
                            display: 'flex',
                            width: '70%',
                            padding: '2px 5px',
                            cursor: 'pointer',
                            color: '#fff',
                            fontSize: '12px',
                          }}
                        >
                          <Typography sx={{ fontSize: '12px' }}>
                            Selecione um motorista
                          </Typography>
                        </Box>
                      )}
                      {externalCompany && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                          }}
                        >
                          <Typography sx={{ fontSize: '12px' }}>
                            {externalCompany}
                          </Typography>
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              );
            },
          },
          { title: 'Placa', field: 'licensePlate', colSpan: 1 },
          { title: 'Id (cliente)', field: 'clientTripId', colSpan: 1 },
          {
            title: 'Centro de custo',
            field: 'sectorName',
            colSpan: 1,
          },
          {
            title: 'Solicitante',
            field: 'requestedByName',
            colSpan: 1,
          },
          {
            title: 'Categoria de veículo solicitado',
            field: 'vehicleCategory',
            colSpan: 1,
          },
          {
            title: 'Passageiro(s)',
            field: 'users',
            colSpan: 1,
            Cell({ entry: { users } }) {
              if (!users) return <></>;
              return (
                <Box
                  sx={{
                    ':hover': {
                      '.users': {
                        display: 'block',
                      },
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                      fontSize: '12px',
                    }}
                    className="users"
                  >
                    {users.map(user => user.name).join(', ')}
                  </Typography>
                </Box>
              );
            },
          },
          {
            title: 'Origem',
            field: 'cityOfOrigin',
            colSpan: 1,
            color: true,
            Cell({ entry: { cityOfOrigin, addressFrom } }) {
              return (
                <Box
                  sx={{
                    ':hover': {
                      '.cityOfOrigin': {
                        display: 'none',
                      },
                      '.addressFrom': {
                        display: 'block',
                      },
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                      fontSize: '12px',
                    }}
                    className="cityOfOrigin"
                  >
                    {cityOfOrigin}
                  </Typography>
                  <Typography
                    sx={{
                      display: 'none',
                      color: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                      fontSize: '12px',
                    }}
                    className="addressFrom"
                  >
                    {addressFrom}
                  </Typography>
                </Box>
              );
            },
          },
          {
            title: 'Paradas',
            field: 'stopPoints',
            colSpan: 1,
            color: true,
            Cell({ entry: { stopPoints } }) {
              return (
                <Box
                  sx={{
                    ':hover': {
                      '.stopPoints': {
                        display: 'none',
                      },
                      '.stopPointsAdresses': {
                        display: 'flex',
                      },
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                      fontSize: '12px',
                    }}
                    className={stopPoints?.length > 0 ? 'stopPoints' : ''}
                  >
                    {stopPoints?.length > 0
                      ? stopPoints.length
                      : 'Sem pontos de paradas'}
                  </Typography>
                  {stopPoints
                    ?.sort((pointA, pointB) => pointA.order - pointB.order)
                    ?.map((stopPoint, index) => (
                      <Box
                        className="stopPointsAdresses"
                        key={index}
                        sx={{
                          display: 'none',
                          alignItems: 'center',
                          gap: '5px',
                          marginTop: '5px',
                        }}
                      >
                        <Place fontSize="small" />
                        <Typography
                          sx={{
                            color:
                              theme.palette.mode === 'dark' ? '#FFF' : '#000',
                            fontSize: '12px',
                          }}
                        >
                          {stopPoint.address}
                        </Typography>
                      </Box>
                    ))}
                </Box>
              );
            },
          },
          {
            title: 'Destino',
            field: 'cityOfDestination',
            colSpan: 1,
            color: true,
            Cell({ entry: { cityOfDestination, addressTo } }) {
              return (
                <Box
                  sx={{
                    ':hover': {
                      '.cityOfDestination': {
                        display: 'none',
                      },
                      '.addressTo': {
                        display: 'block',
                      },
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                      fontSize: '12px',
                    }}
                    className="cityOfDestination"
                  >
                    {cityOfDestination}
                  </Typography>
                  <Typography
                    sx={{
                      display: 'none',
                      color: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                      fontSize: '12px',
                    }}
                    className="addressTo"
                  >
                    {addressTo}
                  </Typography>
                </Box>
              );
            },
          },
          {
            title: 'Data da solicitação',
            field: 'requestDate',
            colSpan: 2,
          },
          { title: 'Data do agendamento', field: 'scheduledDate', colSpan: 2 },
          {
            title: 'Tipo de corrida',
            field: 'type',
            colSpan: 1,
            color: true,
            Cell({ entry: { color, type, background } }) {
              return (
                <>
                  <Box
                    sx={{
                      background,
                      padding: '10px',
                      borderRadius: '20px',
                    }}
                  >
                    <Typography
                      sx={{ color, fontWeight: 'bold', fontSize: '12px' }}
                    >
                      {type}
                    </Typography>
                  </Box>
                </>
              );
            },
          },
          {
            title: 'Status',
            field: 'status',
            colSpan: 1,
            color: true,
            Cell({ entry: { status } }) {
              const statusText: any = {
                requested: 'Aguardando resposta',
                pending: 'Aguardando motorista',
                scheduled: 'Agendado',
                accepted: 'A caminho',
                started: 'Iniciada',
                finished: 'Finalizada',
                requestRejected: 'Rejeitada',
                requestCanceled: 'Cancelada',
              };
              const pallete: any = {
                requested: {
                  background: '#FFDFDF',
                  color: '#CE4F4F',
                },
                pending: {
                  background: '#FFEEDB',
                  color: '#BA7C38',
                },
                scheduled: {
                  background: '#E9F4F5',
                  color: '#316065',
                },
                accepted: {
                  background: '#DDDDFF',
                  color: '#4542DB',
                },
                started: {
                  background: '#CFEEFF',
                  color: '#136490',
                },
                finished: {
                  background: '#CFFFCB',
                  color: '#17880D',
                },
                requestRejected: {
                  background: '#FFDFDF',
                  color: '#CE4F4F',
                },
                requestCanceled: {
                  background: '#DDDDDD',
                  color: '#4E4E4E',
                },
              };
              return (
                <>
                  <Box
                    sx={{
                      background: pallete[status]?.background || '#FFDFDF',
                      width: '120px',
                      padding: '5px',
                      borderRadius: '20px',
                    }}
                  >
                    <Typography
                      sx={{
                        color: pallete[status]?.color || '#CE4F4F',
                        fontWeight: 'bold',
                        fontSize: '12px',
                      }}
                    >
                      {statusText[status]}
                    </Typography>
                  </Box>
                </>
              );
            },
          },
          {
            title: '',
            field: 'id',
            colSpan: 1,
            Cell({
              entry: {
                id,
                scheduledDate,
                cityOfOrigin,
                cityOfDestination,
                users,
                sector,
                status,
                externalCompany,
                finalLat,
                finalLong,
                isJoinedTrip,
                addressFrom,
                addressTo,
                driverId,
                type,
                startLat,
                startLong,
                stopPoints,
                vehicleCategoryId,
                observation,
                companyId,
                manualFinalOdometer,
                manualInitialOdometer,
                manualStartOdometer,
              },
            }) {
              const contentToCopy = `*${
                !scheduledDate ? '--:--' : scheduledDate
              } ${cityOfOrigin} X ${cityOfDestination}*\n
              *Endereço inicial - ${addressFrom}*\n
              *Endereço final - ${addressTo || 'Motorista à disposição'}*\n
              *${users
                ?.map(user => user.name.split(' ')[0])
                .join(', ')} - ${users
                ?.map(user => user.phone)
                .join(', ')}*\n*CC: ${sector?.name}/${sector?.sectorId || ''}*`;
              return (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  {type === 'Recorrente' &&
                    (status === 'scheduled' ||
                      status === 'accepted' ||
                      status === 'requested') && (
                      <Tooltip title="Editar">
                        <IconButton
                          disabled={isStartingExternalCompany}
                          onClick={() => {
                            setSelectedTripToEdit({
                              addressFrom,
                              addressTo,
                              cityOfDestination,
                              cityOfOrigin,
                              finalLat,
                              finalLong,
                              id,
                              companyId,
                              observation,
                              scheduledDate,
                              sectorId: sector.id,
                              startLat,
                              startLong,
                              stopPoints,
                              users: users.map(({ id }) => id),
                              vehicleCategoryId,
                            });
                          }}
                        >
                          <BiPencil size={20} />
                        </IconButton>
                      </Tooltip>
                    )}
                  <Box
                    sx={{
                      display: 'flex',
                      width: isMobile ? '80px' : '100px',
                      justifyContent: 'space-around',
                    }}
                  >
                    {status === 'requested' && isJoinedTrip && (
                      <Tooltip
                        title="Desfazer compartilhamento"
                        sx={{ padding: '0px' }}
                      >
                        <IconButton
                          disabled={isLoadingUndoJoinedTrip}
                          onClick={() => {
                            undoJoinedTrip({ id });
                          }}
                        >
                          <ChangeCircle
                            sx={{
                              color:
                                theme.palette.mode === 'dark'
                                  ? '#FFF'
                                  : '#1d1d1d',
                              fontSize: 20,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                    {status === 'finished' && (
                      <Tooltip title="Editar">
                        <IconButton
                          onClick={() =>
                            setSelectFinishedTripToEdit({
                              tripId: id,
                              manualFinalOdometer,
                              manualInitialOdometer,
                              manualStartOdometer,
                            })
                          }
                        >
                          <BiPencil size={20} />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip
                      title="Copiar para whatsapp"
                      sx={{ padding: '0px' }}
                    >
                      <IconButton
                        onClick={() => handleCopyToClipboard(contentToCopy)}
                      >
                        <ContentCopy
                          sx={{
                            fontSize: 20,
                            color:
                              theme.palette.mode === 'dark'
                                ? '#FFF'
                                : '#1d1d1d',
                          }}
                        />
                      </IconButton>
                    </Tooltip>

                    {(status === 'accepted' ||
                      status === 'started' ||
                      status === 'finished') &&
                      driverId && (
                        <Tooltip
                          title="Link para acompanhar em tempo real"
                          sx={{ padding: '0px' }}
                        >
                          <IconButton
                            onClick={() => generateLinkToViewTrip(id)}
                          >
                            <CallMissedOutgoing
                              sx={{
                                fontSize: 20,
                                color:
                                  theme.palette.mode === 'dark'
                                    ? '#FFF'
                                    : '#1d1d1d',
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    {externalCompany && (
                      <>
                        {(status === 'pending' ||
                          status === 'scheduled' ||
                          status === 'accepted') && (
                          <Tooltip title="Desfazer">
                            <IconButton
                              disabled={isCancelingExternalCompany}
                              onClick={() => {
                                undoExternalCompany({ id });
                              }}
                            >
                              <Replay sx={{ fontSize: 20 }} />
                            </IconButton>
                          </Tooltip>
                        )}
                        {status === 'scheduled' && (
                          <Tooltip title="Motorista a caminho">
                            <IconButton
                              disabled={isAcceptingExternalTrip}
                              onClick={() => {
                                markExternalTripAsAccepted({
                                  id,
                                });
                              }}
                            >
                              <SportsScore sx={{ fontSize: 20 }} />
                            </IconButton>
                          </Tooltip>
                        )}
                        {status === 'accepted' && (
                          <Tooltip title="Iniciar">
                            <IconButton
                              disabled={isStartingExternalCompany}
                              onClick={() => {
                                startExternalCompany({ id });
                              }}
                            >
                              <PlayCircleOutline sx={{ fontSize: 20 }} />
                            </IconButton>
                          </Tooltip>
                        )}
                        {status === 'started' && (
                          <Tooltip title="Finalizar">
                            <IconButton
                              disabled={isFinishing}
                              onClick={() => {
                                finishExternalCompany({
                                  id,
                                  lat: parseFloat(finalLat) || 0,
                                  long: parseFloat(finalLong) || 0,
                                  manualFinalOdometer: 0,
                                });
                              }}
                            >
                              <CheckCircleOutline sx={{ fontSize: 20 }} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </>
                    )}
                    {(status === 'requested' ||
                      status === 'pending' ||
                      status === 'scheduled') && (
                      <Tooltip sx={{ padding: '0px' }} title="Deletar">
                        <IconButton
                          disabled={isRejecting}
                          onClick={() => {
                            toggle();
                            setSelectedId(id);
                          }}
                        >
                          <DeleteSharp sx={{ color: 'red', fontSize: 20 }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                </Box>
              );
            },
          },
        ]}
      />
      <Snackbar
        open={showCopyToast}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowCopyToast(false)}
        message="Copiado para área de transferência"
      />
      <Snackbar
        open={showLimitExceededToast}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowLimitExceededToast(false)}
        message="Limite de corridas selecionadas excedido!"
        ContentProps={{ sx: { background: '#c92929' } }}
      />
      {isOpen && (
        <Modal
          title="Recusar corrida"
          dialogContent={
            <RejectTrip
              refusedTripReason={refusedTripReason}
              setRefusedTripReason={setRefusedTripReason}
              handleReject={handleDelete}
              closeModal={close}
            />
          }
          open={isOpen}
          size="sm"
          onClose={close}
        />
      )}
    </>
  );
};
