import { axios } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { IPriceTags } from '../types';

export async function updateTag({
  companyId,
  name,
  id,
  price,
}: IPriceTags): Promise<IPriceTags> {
  return axios.put(`taggedAdresses/updateTaggedPrice/${id}`, {
    companyId,
    name,
    price,
  });
}

export const useUpdateTag = () => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async data => {
      await queryClient.cancelQueries('tags');
    },
    onError: (error: AxiosError, _, context) => {
      if (context) {
        queryClient.setQueryData('tags', context);
      }
    },
    onSuccess: data => {
      queryClient.invalidateQueries('tags');

      addNotification({
        type: 'success',
        title: 'Sucesso',
        message: 'Tarifa atualizada com sucesso',
      });
      navigate(-1);
    },
    mutationFn: updateTag,
  });
};
