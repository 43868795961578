/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-lone-blocks */
import { Modal } from '@/components';
import { ClockInColumns } from '@/features/clockIn/components/ClockInColumns';
import { useDisclosure } from '@/hooks/useDisclosure';
import { storage } from '@/utils/storage';
import {
  Box,
  Button,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { ZoomIn } from '@mui/icons-material';
import { IWorkJourney } from '@/features/clockIn/api';
import { EditWorkJourney } from '@/features/clockIn/components/EditWorkJourney';
import { AddWorkJourney } from '@/features/clockIn/components/AddWorkJourney';
import { ChipData } from './ReportsListTable';
import { ReportsOptions } from './ReportsOption';
import { IPriceDetais, PriceDetails } from './PriceDetails';

interface ITripData {
  acceptedAt: string;
  acceptedById: string;
  addressFrom: string;
  addressStc: null | string;
  addressTo: string;
  cityOfDestination: string;
  cityOfOrigin: string;
  companyId: string;
  confirmation: string;
  corporateName: string;
  createdAt: string;
  distance: string;
  distanceInTheCellPhone: string;
  driverAvaliation: string;
  driverId: string;
  driverName: string;
  driverObservation: string;
  estimatedDateToFinishTrip: string;
  estimatedDistance: number;
  externalCompany: null | string;
  hasPendency?: boolean;
  finalLat: number;
  finalLatDriverApp: string;
  finalLatDriverStc: string;
  finalLong: number;
  finalLongDriverApp: string;
  finalLongDriverStc: string;
  finalOdometer: null | number;
  firstStopPoint: string;
  fourthStopPoint: string;
  id: string;
  initialOdometer: string;
  isJoinedTrip: boolean;
  joinedTripId: null | string;
  licensePlate: string;
  manualFinalOdometer: number;
  manualInitialOdometer: number;
  manualStartOdometer: number;
  masterId: null | string;
  name: string;
  observation: null | string;
  passengerAvaliation: number;
  passengerObservation: string;
  recurringTripId: null | string;
  refusedById: null | string;
  refusedTripReason: null | string;
  renterId: string;
  requestDate: string;
  requestedById: string;
  requestedVehicleClassId: null | string;
  scheduledDate: null | string;
  secondStopPoint: string;
  sector: string;
  sectorId: string;
  startLat: number;
  startLong: number;
  startTripDate: string;
  status: string;
  stopPoints: any[];
  thirdStopPoint: string;
  totalNotStartedTimeWaited: string;
  totalStartedTimeWaited: string;
  totalTimeWaited: string;
  travelTime: string;
  tripDetails: IPriceDetais;
  tripFinalDate: string;
  tripPrice: string;
  tripPriceInTheCellPhone: string;
  type: string;
  updatedAt: string;
  userTripAnswer: {
    id: string;
    userId: string;
    driverId: string;
    hasTripFinishedInTheRightPlace: boolean;
    message: null | string;
  };
  usersSharingTrip: [];
  vehicleCategoryId: null | string;
  vehicleClassId: null | string;
}

interface IHandleOptions {
  reportType:
    | 'trips'
    | 'workJourney'
    | 'vehicleExchange'
    | 'location'
    | 'timeRelation'
    | 'canceledAndFinishedTripsRelation'
    | 'passengersRanking'
    | 'operatorsRanking'
    | 'acceptedTimeRelation'
    | 'vehicleAndTripsRelation'
    | 'driversRanking'
    | 'sectorRelation';
  chipData: ChipData[];
  removedChips: ChipData[];
  setChipData: (chipData: ChipData[]) => void;
  setRemovedChips: (removedChips: ChipData[]) => void;
  selectedDriver?: string;
}

export const HandleOptions = ({
  reportType,
  setChipData,
  setRemovedChips,
  chipData,
  removedChips,
  selectedDriver,
}: IHandleOptions) => {
  const { close, isOpen, toggle } = useDisclosure();
  const {
    close: closeAddWorkJourney,
    isOpen: isAddWorkJourneyOpen,
    open: openAddWorkJourney,
  } = useDisclosure();
  const [priceDetails, setPriceDetails] = useState<IPriceDetais | null>(null);
  const [editWorkJourney, setEditWorkJourney] = useState<IWorkJourney | null>(
    null,
  );
  useEffect(() => {
    const handleOption = () => {
      switch (reportType) {
        case 'trips':
          {
            const chipsOnLocalStorage = storage.getItem({
              key: reportType,
              storageType: 'local',
            });
            if (chipsOnLocalStorage) {
              const hasTripValue = chipsOnLocalStorage?.findIndex(
                (chip: any) => chip.field === 'tripPrice',
              );
              const hasLicensePlate = chipsOnLocalStorage?.findIndex(
                (chip: any) => chip.field === 'licensePlate',
              );

              const hasStatus = chipsOnLocalStorage?.findIndex(
                (chip: any) => chip.field === 'status',
              );

              const hasRejectReason = chipsOnLocalStorage?.findIndex(
                (chip: any) => chip.field === 'refusedTripReason',
              );

              if (hasLicensePlate === -1) {
                chipsOnLocalStorage[chipsOnLocalStorage.length] = {
                  key: 20,
                  title: 'Placa',
                  field: 'licensePlate',
                };
              }
              if (hasStatus === -1) {
                chipsOnLocalStorage[chipsOnLocalStorage.length] = {
                  key: 39,
                  title: 'Status',
                  field: 'status',
                };
              }

              if (hasRejectReason === -1) {
                chipsOnLocalStorage[chipsOnLocalStorage.length] = {
                  key: 40,
                  title: 'Motivo da recusa',
                  field: 'refusedTripReason',
                };
              }
              if (hasTripValue !== -1) {
                chipsOnLocalStorage[hasTripValue] = {
                  key: 11,
                  title: 'Valor (R$)',
                  field: 'tripPrice',
                  Cell({ entry }: { entry: ITripData }) {
                    return (
                      <Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Typography>{entry.tripPrice}</Typography>
                          {entry.tripDetails && (
                            <Tooltip title="Ver extrato">
                              <IconButton
                                onClick={() => {
                                  setPriceDetails({
                                    ...entry.tripDetails,
                                    stopPoints: entry.stopPoints,
                                  });
                                }}
                              >
                                <ZoomIn />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                        {entry.hasPendency && (
                          <Typography sx={{ fontSize: '10px' }}>
                            Valor sujeito a alteração
                          </Typography>
                        )}
                      </Box>
                    );
                  },
                };
              }

              setChipData(chipsOnLocalStorage);
            } else {
              setChipData([
                { key: 0, title: 'Empresa', field: 'corporateName' },
                { key: 1, title: 'Setor', field: 'sector' },
                { key: 2, title: 'Passageiro', field: 'name' },
                { key: 3, title: 'Motorista', field: 'driverName' },
                { key: 20, title: 'Placa', field: 'licensePlate' },
                { key: 4, title: 'Solicitada', field: 'requestDate' },
                { key: 5, title: 'Inicio', field: 'startTripDate' },
                { key: 6, title: 'Fim', field: 'tripFinalDate' },
                {
                  key: 7,
                  title: 'Distância(KM)',
                  field: 'distance',
                },
                {
                  key: 19,
                  title: 'Distância Celular (KM)',
                  field: 'distanceInTheCellPhone',
                },
                {
                  key: 8,
                  title: 'Partida',
                  field: 'addressFrom',
                },
                {
                  key: 9,
                  title: 'Destino',
                  field: 'addressTo',
                },
                {
                  key: 20,
                  title: 'Avaliação do motorista',
                  field: 'driverAvaliation',
                },
                {
                  key: 21,
                  title: 'Avaliação do passageiro',
                  field: 'passengerAvaliation',
                },
                {
                  key: 22,
                  title: 'Observação do motorista',
                  field: 'driverObservation',
                },
                {
                  key: 23,
                  title: 'Observação do passageiro',
                  field: 'passengerObservation',
                },
                {
                  key: 24,
                  title: 'Hodômetro inicial',
                  field: 'manualInitialOdometer',
                },
                {
                  key: 25,
                  title: 'Hodômetro final',
                  field: 'manualFinalOdometer',
                },
                {
                  key: 39,
                  title: 'Status',
                  field: 'status',
                },
                {
                  key: 40,
                  title: 'Motivo da recusa',
                  field: 'refusedTripReason',
                },
                {
                  key: 10,
                  title: 'Valor (R$)',
                  field: 'tripPrice',
                  Cell({
                    entry: { tripPrice, tripDetails, stopPoints },
                  }: {
                    entry: ITripData;
                  }) {
                    return (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Typography>{tripPrice}</Typography>
                        {tripDetails && (
                          <Tooltip title="Ver extrato">
                            <IconButton
                              onClick={() => {
                                setPriceDetails({
                                  ...tripDetails,
                                  stopPoints,
                                });
                              }}
                            >
                              <ZoomIn />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    );
                  },
                },
              ]);
            }
            const removedChipsOnLocalStorage = storage.getItem({
              key: `${reportType}Removed`,
              storageType: 'local',
            });
            if (removedChipsOnLocalStorage) {
              setRemovedChips(removedChipsOnLocalStorage);
            } else {
              setRemovedChips([
                {
                  key: 11,
                  title: 'Valor estimado (R$)',
                  field: 'estimatedPrice',
                },
                {
                  key: 12,
                  title: 'Localização final do rastreador',
                  field: 'addressStc',
                },
                {
                  key: 13,
                  title: 'Localização final do app',
                  field: 'lastPositionOnApp',
                },
                {
                  key: 14,
                  title: 'Passageiro chegou ao destino',
                  field: 'confirmation',
                },
                {
                  key: 15,
                  title: 'Primeiro ponto de parada',
                  field: 'firstStopPoint',
                },
                {
                  key: 16,
                  title: 'Segundo ponto de parada',
                  field: 'secondStopPoint',
                },
                {
                  key: 17,
                  title: 'Terceiro ponto de parada',
                  field: 'thirdStopPoint',
                },
                {
                  key: 18,
                  title: 'Quarto ponto de parada',
                  field: 'fourthStopPoint',
                },
              ]);
            }
          }
          break;
        case 'workJourney':
          {
            const { columns } = ClockInColumns({
              onClickEdit: entry => {
                setEditWorkJourney(entry);
              },
              onClickAddWorkJourney: () => {
                openAddWorkJourney();
              },
            });

            setChipData(columns);

            setRemovedChips([]);
          }
          break;
        case 'location':
          {
            setChipData([]);
          }
          break;
        case 'vehicleExchange':
          {
            const chipsOnLocalStorage = storage.getItem({
              key: reportType,
              storageType: 'local',
            });
            if (chipsOnLocalStorage) {
              setChipData(chipsOnLocalStorage);
            } else {
              setChipData([
                { key: 1, title: 'Data', field: 'date' },
                { key: 2, title: 'Modelo', field: 'model' },
                { key: 3, title: 'Placa', field: 'licensePlate' },
                { key: 4, title: 'Motorista', field: 'driverName' },
                { key: 5, title: 'Descrição', field: 'description' },
              ]);
            }
            const removedChipsOnLocalStorage = storage.getItem({
              key: `${reportType}Removed`,
              storageType: 'local',
            });
            if (removedChipsOnLocalStorage) {
              setRemovedChips(removedChipsOnLocalStorage);
            } else {
              setRemovedChips([]);
            }
          }
          break;
        case 'vehicleAndTripsRelation':
          {
            const chipsOnLocalStorage = storage.getItem({
              key: reportType,
              storageType: 'local',
            });
            if (chipsOnLocalStorage) {
              setChipData(chipsOnLocalStorage);
            } else {
              setChipData([
                { key: 1, title: 'Data inicial', field: 'startDate' },
                { key: 2, title: 'Data final', field: 'endDate' },
                { key: 3, title: 'Placa', field: 'licensePlate' },
                { key: 4, title: 'Motorista', field: 'driverName' },
                {
                  key: 5,
                  title: 'Hodômetro inicial',
                  field: 'initialHodometer',
                },
                { key: 6, title: 'Hodômetro final', field: 'finalHodometer' },
                { key: 7, title: 'Distância percorrida', field: 'totalKm' },
              ]);
            }
            const removedChipsOnLocalStorage = storage.getItem({
              key: `${reportType}Removed`,
              storageType: 'local',
            });
            if (removedChipsOnLocalStorage) {
              setRemovedChips(removedChipsOnLocalStorage);
            } else {
              setRemovedChips([]);
            }
          }
          break;
        default: {
          return <></>;
        }
      }
      return null;
    };
    handleOption();
  }, [reportType, setChipData, setRemovedChips]);
  return (
    <>
      <Modal
        title="Extrato de cobrança da corrida"
        dialogContent={<PriceDetails data={priceDetails} />}
        open={!!priceDetails}
        size="md"
        onClose={() => {
          setPriceDetails(null);
        }}
      />
      {editWorkJourney && (
        <Modal
          title="Editar jornada"
          dialogContent={
            <EditWorkJourney
              closeModal={() => setEditWorkJourney(null)}
              data={editWorkJourney}
            />
          }
          open={!!editWorkJourney}
          size="xs"
          onClose={() => {
            setEditWorkJourney(null);
          }}
        />
      )}
      {isAddWorkJourneyOpen && (
        <Modal
          title="Adicionar jornada"
          dialogContent={
            <AddWorkJourney
              selectedDriver={selectedDriver}
              closeModal={() => {
                closeAddWorkJourney();
              }}
            />
          }
          open={!!isAddWorkJourneyOpen}
          size="xs"
          onClose={() => {
            closeAddWorkJourney();
          }}
        />
      )}
      <Toolbar>
        <Button variant="outlined" onClick={() => toggle()}>
          Opções
        </Button>
      </Toolbar>
      <Modal
        title="Selecionar opções"
        dialogContent={
          <ReportsOptions
            storegeKey={reportType}
            chipData={chipData}
            setChipData={setChipData}
            removedChips={removedChips}
            setRemovedChips={setRemovedChips}
          />
        }
        open={isOpen}
        size="md"
        onClose={close}
      />
    </>
  );
};
