import { Modal } from '@/components';
import { useDisclosure } from '@/hooks/useDisclosure';
import { CssBaseline, useMediaQuery, useTheme, Box } from '@mui/material';
import { useState } from 'react';
import { DriversValues } from '@/features/drivers';
import { useRejectTrip, useTripsOnHold } from '../../api';
import { AcceptTrip } from '../AcceptTrip';
import { RejectTrip } from '../RejectTrip';
import useStyle from '../../styles/style';
import { loadOpenTripsResponse } from '../../types';
import { OpenTripGrid } from './OpenTripGrid';
import { TripCard } from '../TripCard';

export const OpenTrip = ({
  openTripsData,
  drivers,
  selectedRows,
  setSelectedRows,
  isGridOption = true,
}: {
  openTripsData: loadOpenTripsResponse[] | undefined;
  drivers: DriversValues[];
  selectedRows: Set<string>;
  setSelectedRows: (value: Set<string>) => void;
  isGridOption?: boolean;
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyle({ matches });
  const { close, toggle, isOpen } = useDisclosure();
  const [acceptTripModal, setAcceptTripModal] = useState(false);
  const [rejectTripModal, setRejectTripModal] = useState(false);
  const [currentTripId, setCurrentTripId] = useState('');
  const [currentUserId, setCurrentUserId] = useState('');
  const [refusedTripReason, setRefusedTripReason] = useState('');
  const [requestedVehicle, setRequestedVehicle] = useState('');

  const { refetch: refetchTripsOnHold } = useTripsOnHold();

  const { mutateAsync: rejectTrip } = useRejectTrip();

  const handleReject = () => {
    rejectTrip({
      tripId: currentTripId,
      refusedTripReason,
    });
    close();
  };

  return (
    <>
      <Box sx={{ marginBottom: '5px' }} className={classes.CardWrapper}>
        {isGridOption ? (
          <Box sx={{ height: '100%', overflowX: 'auto' }}>
            <OpenTripGrid
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              drivers={drivers || []}
              openTripsData={openTripsData || []}
              AcceptTripModal={({ userId, id, vehicleCategoryName }) => {
                toggle();
                setAcceptTripModal(true);
                setRejectTripModal(false);
                setCurrentUserId(userId);
                setCurrentTripId(id);
                setRequestedVehicle(vehicleCategoryName || '');
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'flex-start',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
            }}
          >
            {openTripsData?.map(trip => (
              <TripCard
                key={trip?.id}
                cityOfDestination={trip?.cityOfDestination}
                cityOfOrigin={trip?.cityOfOrigin}
                sector={trip?.sector}
                users={trip?.users}
                id={trip?.masterId || trip?.id}
                drivers={drivers}
                externalCompany={trip?.externalCompany}
                usersSharingTrip={trip?.users}
                isJoinedTrip={trip?.isJoinedTrip}
                finalLat={trip?.finalLat}
                finalLong={trip?.finalLong}
                corporateName={trip?.corporateName}
                addressFrom={trip?.addressFrom}
                addressTo={trip?.addressTo}
                distance={trip?.distance?.toString()}
                driverName={trip?.driverName}
                scheduledDate={trip?.scheduledDate}
                travelTime={trip?.travelTime}
                status={trip?.status}
                requestDate={trip?.requestDate}
                driver={trip?.driver}
                driverId={trip?.driverId}
                stopPoints={trip?.stopPoints}
                vehicleCategoryName={trip?.vehicleCategoryName}
                estimatedPrice={trip?.estimatedPrice}
                AcceptTripModal={() => {
                  toggle();
                  setAcceptTripModal(true);
                  setRejectTripModal(false);
                  setCurrentUserId(trip?.userId);
                  setCurrentTripId(trip?.id);
                  setRequestedVehicle(trip?.vehicleCategoryName || '');
                }}
                RejectTripModal={() => {
                  toggle();
                  setCurrentUserId(trip?.userId);
                  setRejectTripModal(true);
                  setAcceptTripModal(false);
                  setCurrentTripId(trip?.id);
                }}
              />
            ))}
          </Box>
        )}
      </Box>
      {isOpen && acceptTripModal && (
        <Modal
          title="Aceitar corrida"
          dialogContent={
            <AcceptTrip
              requestedVehicle={requestedVehicle}
              tripId={currentTripId}
              userId={currentUserId}
              closeModal={close}
              refetchTripsOnHold={refetchTripsOnHold}
            />
          }
          open={isOpen}
          size="md"
          onClose={close}
        />
      )}

      {isOpen && rejectTripModal && (
        <Modal
          title="Recusar corrida"
          dialogContent={
            <RejectTrip
              refusedTripReason={refusedTripReason}
              setRefusedTripReason={setRefusedTripReason}
              handleReject={handleReject}
              closeModal={close}
            />
          }
          open={isOpen}
          size="sm"
          onClose={close}
        />
      )}

      <CssBaseline />
    </>
  );
};
