import { Form, InputField } from '@/components';
import {
  Box,
  Button,
  InputAdornment,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import * as z from 'zod';
import { Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useNotificationStore } from '@/stores';
import { ICreatePriceTagDTO } from '../types';
import { useCreateTag } from '../api';

const schema = z.object({
  name: z
    .string({ required_error: 'Campo obrigatório' })
    .min(1, 'Mínimo 1 caracter'),
  price: z.number({ required_error: 'Campo obrigatório' }),
});

export const CreateTag = () => {
  const { companyId } = useParams();

  const navigate = useNavigate();
  const theme = useTheme();
  const { mutateAsync, isLoading } = useCreateTag();
  const { addNotification } = useNotificationStore();
  return (
    <Paper sx={{ maxWidth: '1000px', margin: 'auto' }}>
      <Box>
        <Form<ICreatePriceTagDTO, typeof schema>
          id="create-tag"
          onSubmit={values => {
            if (!companyId) {
              return addNotification({
                title: 'Selecione uma empresa',
                type: 'error',
              });
            }
            return mutateAsync({ ...values, companyId });
          }}
          schema={schema}
          options={{
            defaultValues: {
              name: '',
              price: 0,
            },
          }}
        >
          {({ formState, control }) => (
            <>
              <Typography
                sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'red' }}
              >
                Cadastro de tarifa
              </Typography>
              <Box>
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      size="small"
                      name="name"
                      type="text"
                      label="Tarifa"
                      value={value}
                      onChange={onChange}
                      placeholder="A"
                      error={!!formState.errors.name?.message}
                      errorMessage={formState.errors.name?.message}
                    />
                  )}
                />
                <Controller
                  name="price"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        ),
                        inputProps: { min: 0, step: 0.01 },
                      }}
                      size="small"
                      name="price"
                      type="number"
                      label="Preço da tarifa"
                      value={Number(value) || null}
                      onChange={e => onChange(Number(e.target.value))}
                      placeholder="0"
                      errorMessage={formState.errors.price?.message}
                      error={!!formState.errors.price}
                    />
                  )}
                />
                <Box sx={{ display: 'flex', marginTop: '10px', gap: '5px' }}>
                  <Button
                    variant="contained"
                    color="success"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? 'Carregando' : 'Adicionar'}
                  </Button>
                  <Button
                    onClick={() => navigate(-1)}
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                  >
                    Voltar
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Form>
      </Box>
    </Paper>
  );
};
