/* eslint-disable no-param-reassign */
import * as z from 'zod';
import { Form, InputField } from '@/components';
import {
  Backdrop,
  CircularProgress,
  Grid,
  Box,
  MenuItem,
  Button,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { cpfMask, phoneMask } from '@/utils/maskShared';
import { makeStyles } from '@mui/styles';
import { useSectorsByCompany } from '@/features/sectors';
import { useAuth } from '@/providers/auth';
import { useCreatePassengerAsRenter, UserValues } from '@/features/users';

const emailSchema = z.object({
  email: z
    .string({ required_error: 'Campo obrigatório' })
    .min(1, 'Campo obrigatório'),
  password: z
    .string({ required_error: 'Campo obrigatório' })
    .min(6, 'Mínimo 6 caracteres')
    .max(15, 'Máximo 15 caracteres'),
  name: z
    .string({ required_error: 'Campo obrigatório' })
    .min(1, 'Campo obrigatório'),
  phone: z
    .string({ required_error: 'Campo obrigatório' })
    .min(12, 'Mínimo 12 caracteres')
    .max(15, 'Máximo 15 caracteres'),
  individualRegistration: z.string().max(14, 'Máximo 14 caracteres').optional(),
  registration: z.string().optional(),
  thirdPartyCompany: z.string().optional(),
  sectorId: z.string().optional(),
  accessType: z.string().optional(),
  willHaveAccess: z.literal(true),
});

const noEmailSchema = z.object({
  name: z
    .string({ required_error: 'Campo obrigatório' })
    .min(1, 'Campo obrigatório'),
  phone: z
    .string({ required_error: 'Campo obrigatório' })
    .min(12, 'Mínimo 12 caracteres')
    .max(15, 'Máximo 15 caracteres'),
  registration: z.string().optional(),
  thirdPartyCompany: z.string().min(1, 'Campo obrigatório').optional(),
  sectorId: z.string().optional(),
  accessType: z.string().optional(),
  willHaveAccess: z.literal(false),
});
const formSchema = z.discriminatedUnion('willHaveAccess', [
  emailSchema,
  noEmailSchema,
]);

interface IRequest {
  onSuccess: () => void;
  companyId: string;
}
export function CreatePassengerAsRenter({ companyId, onSuccess }: IRequest) {
  const { data: sectorsList, isLoading: isLoadingSectorList } =
    useSectorsByCompany(companyId);

  const { mutateAsync, isLoading: loadingAdd } = useCreatePassengerAsRenter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const useStyles = makeStyles(() => ({
    wrapper: {
      marginLeft: ({ isMobile }: { isMobile?: boolean }) =>
        isMobile ? '0px' : '10px',
    },
  }));

  const classes = useStyles({ isMobile });

  return (
    <Box>
      <Box>
        <Form<UserValues, typeof formSchema>
          id="create-user"
          onSubmit={values => {
            const data = {
              ...values,
              status: true,
              accessType: 'user',
              companyId,
            };
            mutateAsync(data, {
              onSuccess: () => {
                onSuccess();
              },
            });
          }}
          schema={formSchema}
        >
          {({ register, formState, watch, getValues, setValue }) => {
            watch('accessType');
            watch('willHaveAccess');
            return (
              <>
                {isLoadingSectorList ? (
                  <Backdrop
                    sx={{
                      color: '#fff',
                      zIndex: theme => theme.zIndex.drawer + 1,
                    }}
                    open={isLoadingSectorList}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                ) : (
                  <>
                    <Grid container>
                      <Grid item sm={6} xs={12}>
                        <>
                          <InputField
                            size="small"
                            name="accessType"
                            type="text"
                            select
                            label="Tipo de acesso"
                            registration={register('accessType')}
                            error={!!formState.errors.accessType}
                            errorMessage={formState.errors.accessType?.message}
                          >
                            <MenuItem
                              onClick={() => setValue('willHaveAccess', true)}
                              value="user"
                            >
                              Passageiro com acesso
                            </MenuItem>
                            <MenuItem
                              onClick={() => setValue('willHaveAccess', false)}
                              value="user-without-access"
                            >
                              Passageiro sem acesso
                            </MenuItem>
                            <MenuItem
                              onClick={() => setValue('willHaveAccess', false)}
                              value="user-third-party"
                            >
                              Passageiro externo
                            </MenuItem>
                          </InputField>
                          <InputField
                            size="small"
                            name="sectorId"
                            select
                            label="Setor"
                            registration={register('sectorId')}
                            error={!!formState.errors.sectorId}
                            errorMessage={formState.errors.sectorId?.message}
                          >
                            {sectorsList?.sort().map((item: any, key: any) => {
                              return (
                                // eslint-disable-next-line react/no-array-index-key
                                <MenuItem key={key} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </InputField>
                        </>
                        {getValues('accessType') !== 'user-third-party' &&
                          getValues('accessType') !== 'user-without-access' && (
                            <>
                              <InputField
                                size="small"
                                name="email"
                                type="text"
                                label="Login"
                                registration={register('email')}
                                error={!!formState.errors.email}
                                errorMessage={formState.errors.email?.message}
                                placeholder="Login do usuário para acesso no app"
                              />
                              <InputField
                                size="small"
                                name="password"
                                type="password"
                                label="Senha"
                                registration={register('password')}
                                error={!!formState.errors.password}
                                errorMessage={
                                  formState.errors.password?.message
                                }
                                placeholder="Senha para acesso"
                              />
                            </>
                          )}

                        <InputField
                          size="small"
                          name="name"
                          type="text"
                          label="Nome"
                          registration={register('name')}
                          error={!!formState.errors.name}
                          errorMessage={formState.errors.name?.message}
                          placeholder="Nome do usuário"
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <Box className={classes.wrapper}>
                          <InputField
                            size="small"
                            name="registration"
                            type="text"
                            label="Matrícula"
                            registration={register('registration')}
                            error={!!formState.errors.registration}
                            errorMessage={
                              formState.errors.registration?.message
                            }
                            placeholder="Matrícula"
                          />
                          <InputField
                            size="small"
                            name="phone"
                            type="text"
                            label="Telefone"
                            registration={register('phone')}
                            error={!!formState.errors.phone}
                            errorMessage={formState.errors.phone?.message}
                            placeholder="(99) 99999-9999"
                            inputProps={{ minLength: 15, maxLength: 15 }}
                            onChange={(event: any) => {
                              const { value } = event.target;
                              event.target.value = phoneMask(
                                value.replace(/[^\d]+/g, ''),
                              );
                            }}
                          />
                          {getValues('accessType') !== 'user-third-party' && (
                            <InputField
                              size="small"
                              name="individualRegistration"
                              type="text"
                              label="CPF"
                              registration={register('individualRegistration')}
                              error={!!formState.errors.individualRegistration}
                              errorMessage={
                                formState.errors.individualRegistration?.message
                              }
                              placeholder="999.999.999-99"
                              inputProps={{ minLength: 14, maxLength: 14 }}
                              onChange={(event: any) => {
                                const { value } = event.target;
                                event.target.value = cpfMask(value);
                              }}
                            />
                          )}
                          {getValues('accessType') === 'user-third-party' && (
                            <InputField
                              size="small"
                              name="thirdPartyCompany"
                              type="text"
                              label="Empresa terceirizada"
                              registration={register('thirdPartyCompany')}
                              error={!!formState.errors.thirdPartyCompany}
                              errorMessage={
                                formState.errors.thirdPartyCompany?.message
                              }
                              placeholder="Nome da empresa terceirizada"
                            />
                          )}
                        </Box>
                      </Grid>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Button
                          sx={{ marginTop: '10px' }}
                          variant="contained"
                          color="success"
                          type="submit"
                          disabled={loadingAdd}
                        >
                          {loadingAdd ? 'Carregando...' : 'Adicionar'}
                        </Button>
                      </Box>
                    </Grid>
                  </>
                )}
              </>
            );
          }}
        </Form>
      </Box>
    </Box>
  );
}
